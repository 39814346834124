import styled from 'styled-components';

export const TForm = styled.form`
  width: 468px;
  height: 690px;
  padding: 46px 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  // box-sizing: border-box;
  // border: 2px solid #EAEAEB;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  // box-shadow: 1px 1px 20px rgba(122, 120, 233, 0.2);
`;

export const TLogosContainer = styled.div`
  margin: 30px auto 0;
  text-align: center;

  & > * {
    margin-right: 46px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;
