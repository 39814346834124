import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";
// import { ApiDoctor } from "../../../services/Doctors";
import { ApiLocalization } from "../../../services/Localizations";
import { ApiDiagnosis } from "../../../services/Diagnoses";

export type ClinicalDataState = {
  localizations: Array<ApiLocalization>;

  diagnoses: Array<ApiDiagnosis>;

  selectedLocalization: {
    label: string;
    value: string;
  } | null;

  selectedDiagnosis: {
    label: string;
    value: string;
  } | null;

  clinicalDiagnosis: string | null;

  t: {
    label: string;
    value: string;
  } | null;

  tSub: {
    label: string;
    value: string;
  } | null;

  n: {
    label: string;
    value: string;
  } | null;

  nSub: {
    label: string;
    value: string;
  } | null;

  m: {
    label: string;
    value: string;
  } | null;

  mSub: {
    label: string;
    value: string;
  } | null;

  stage: string | null;
  sub: string | null;

  additionalClinicalData: string | null;
  previousResults: string | null;

  treatment: string | null;
};

const initialState: ClinicalDataState = {
  localizations: [],
  diagnoses: [],
  selectedLocalization: null,
  selectedDiagnosis: null,

  clinicalDiagnosis: null,
  t: null,
  tSub: null,

  n: null,
  nSub: null,

  m: null,
  mSub: null,

  stage: null,
  sub: null,

  additionalClinicalData: null,
  previousResults: null,

  treatment: null,
};

export const clinicalDataSlice = createSlice({
  name: "clinicalData",
  initialState,
  reducers: {
    setLocalizations: (
      state,
      action: PayloadAction<Array<ApiLocalization>>
    ) => {
      state.localizations = action.payload;
    },

    setDiagnoses: (state, action: PayloadAction<Array<ApiDiagnosis>>) => {
      state.diagnoses = action.payload;
    },

    setSelectedLocalization: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.selectedLocalization = action.payload;
    },

    setSelectedDiagnosis: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.selectedDiagnosis = action.payload;
    },

    setClinicalDiagnosis: (state, action: PayloadAction<string | null>) => {
      state.clinicalDiagnosis = action.payload;
    },

    setT: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.t = action.payload;
    },

    setTSub: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.tSub = action.payload;
    },

    setN: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.n = action.payload;
    },

    setNSub: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.nSub = action.payload;
    },

    setM: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.n = action.payload;
    },

    setMSub: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.nSub = action.payload;
    },

    setStage: (state, action: PayloadAction<string | null>) => {
      state.stage = action.payload;
    },

    setSub: (state, action: PayloadAction<string | null>) => {
      state.sub = action.payload;
    },

    setAdditionalClinicalData: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.additionalClinicalData = action.payload;
    },

    setPreviousResults: (state, action: PayloadAction<string | null>) => {
      state.previousResults = action.payload;
    },

    setTreatment: (state, action: PayloadAction<string | null>) => {
      state.treatment = action.payload;
    },
  },
});

export const {
  setLocalizations,
  setDiagnoses,
  setSelectedLocalization,
  setSelectedDiagnosis,
  setClinicalDiagnosis,
  setT,
  setTSub,
  setN,
  setNSub,
  setM,
  setMSub,
  setStage,
  setSub,
  setAdditionalClinicalData,
  setPreviousResults,
  setTreatment,
} = clinicalDataSlice.actions;

export const clinicalDataReducer = clinicalDataSlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

export const localizationsSelector = createSelector(
  getState,
  (state) => state.clinicalData.localizations
);

export const diagnosesSelector = createSelector(
  getState,
  (state) => state.clinicalData.diagnoses
);

export const selectedLocalizationSelector = createSelector(
  getState,
  (state) => state.clinicalData.selectedLocalization
);

export const selectedDiagnosisSelector = createSelector(
  getState,
  (state) => state.clinicalData.selectedDiagnosis
);

export const clinicalDiagnosisSelector = createSelector(
  getState,
  (state) => state.clinicalData.clinicalDiagnosis
);

export const tSelector = createSelector(
  getState,
  (state) => state.clinicalData.t
);

export const tSubSelector = createSelector(
  getState,
  (state) => state.clinicalData.tSub
);

export const nSelector = createSelector(
  getState,
  (state) => state.clinicalData.n
);

export const nSubSelector = createSelector(
  getState,
  (state) => state.clinicalData.nSub
);

export const mSelector = createSelector(
  getState,
  (state) => state.clinicalData.m
);

export const mSubSelector = createSelector(
  getState,
  (state) => state.clinicalData.mSub
);

export const stageSelector = createSelector(
  getState,
  (state) => state.clinicalData.stage
);

export const subSelector = createSelector(
  getState,
  (state) => state.clinicalData.sub
);

export const additionalClinicalDataSelector = createSelector(
  getState,
  (state) => state.clinicalData.additionalClinicalData
);

export const previousResultsSelector = createSelector(
  getState,
  (state) => state.clinicalData.previousResults
);

export const treatmentSelector = createSelector(
  getState,
  (state) => state.clinicalData.treatment
);
