import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../images/logo.svg';
import { ReactComponent as SmallLogoIcon } from '../../images/small-logo.svg';

export const TMenuWr = styled.div`
  position: relative;
  cursor: default;
`;

export const TUserItemLinkWr = styled(NavLink)`
  text-decoration: none;
  display: block;
`;

const TTitleTemplate = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  display: block;
  position: absolute;
  top: 25px;
  transition: left .3s;
`;

export const TTitle = styled(TTitleTemplate)`
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 14px 9px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 20px;
  left: -1000px;
`;

export const TSmallTitle = styled(TTitleTemplate)`
  top: 34px;
  line-height: 40px;
  left: 0;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 12px 12px 2px 12px;
  padding: 4px 2px;
  width: 50px;
  text-align: center;
  font-size: 18px;
`;

export const TDivider = styled.div`
  margin: 104px 0 12px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2)};
`;

export const TMenuItemsContainer = styled.div`
  min-height: 512px;
`;

export const TIconWrapper = styled.div`
  margin-right: 0;
  width: 50px;
  min-width: 50px;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px 12px 2px 12px;
`;

const TMenuItem = css`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 16px;
  
  cursor: pointer;

  span {
    display: none;
  }

  &:hover {
    font-size: 14px;
    font-weight: bold;

    ${TIconWrapper} {
      background: linear-gradient(90deg, #FFFFFF -29.59%, #FFFFFF 160.2%);

      & > svg {
        filter: invert(1);
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TMenuLink = styled(NavLink)`
  ${TMenuItem};
  text-decoration: none;
`;

export const TMenuButton = styled.div`
  ${TMenuItem};
`;

export const TLogo = styled(LogoIcon)`
  width: 100%;
  height: auto;
  display: none;
  margin-bottom: 56px;
`;

export const TSmallLogo = styled(SmallLogoIcon)`
  margin-bottom: 56px;
`;

export const TWrapper = styled.div`
  display: flex;
  position: sticky;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 12px;
  width: 74px;
  height: 100vh;
  border-radius: 0 20px 0 0;
  background: #3E6AFF;
  box-sizing: border-box;
  -webkit-transition: width .6s; 
  -moz-transition: width .6s; 
  -ms-transition: width .6s; 
  -o-transition: width .6s; 
  transition: width .6s; 
  will-change: width;
  z-index: 20;

  &:hover {
    width: 216px;
    & ${TTitle} {
      left: 0;
    }
    & ${TSmallTitle} {
      left: -1000px;
    }
    & ${TLogo}, & ${TMenuLink} span, & ${TMenuButton} span {
      display: block;
    }
    & ${TSmallLogo} {
      display: none;
    }
    & ${TIconWrapper} {
      margin-right: 5px;
    }

    & .activeMenuItem {
      & ${TIconWrapper} {
        margin-right: 17px;
      }
    }
  }

  & .activeUserItem {
    svg {
      stroke:white;
      stroke-width: 2;
    }

    & ${TTitle} {
       border-color: ${({ theme }) => theme.colors.white};
    }
    & ${TSmallTitle} {
      background-color: ${({ theme }) => theme.colors.white};
      color: #000;
    }
  }
  
  & .activeMenuItem {
    font-size: 14px;
    font-weight: bold;

    & ${TIconWrapper} {
      margin-right: 0;
      background: linear-gradient(90deg, #FFFFFF -29.59%, #FFFFFF 160.2%);

      & > svg {
        filter: invert(1);
      }
    }
  }
`;
