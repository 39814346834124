import styled from "styled-components";
import { Title } from "../../../../componentsNew/Title";
import { Radio } from "../../../../componentsNew/Radio";
import { Select } from "../../../../componentsNew/Select";
import { Datepicker } from "../../../../componentsNew/Datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  dateSelector,
  materialSelector,
  materialTypeSelector,
  // obtainingSelector,
  sampleTypeSelector,
  setDate,
  setMaterial,
  setMaterialType,
  setObtaining,
  setSampleType,
} from "../../redux/biomaterialRegistration";

export const BiomaterialRegistration = () => {
  const dispatch = useDispatch();

  const material = useSelector(materialSelector);
  const materialType = useSelector(materialTypeSelector);
  // const obtaining = useSelector(obtainingSelector);
  const sampleType = useSelector(sampleTypeSelector);
  const date = useSelector(dateSelector);

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Регистрация биоматериала</Title>
      </StyledTitleContainer>

      <StyledRadioContainer>
        <Radio
          title="Вид материала"
          selected={material === "Парафиновый блок + Гист. стекло"}
          text="Парафиновый блок + Гист. стекло"
          onSelect={() => {
            dispatch(setMaterial("Парафиновый блок + Гист. стекло"));
          }}
          tooltip={false}
          required={true}
        />
      </StyledRadioContainer>

      <StyledRadioGroupContainer>
        <StyledRadioContainer>
          <Radio
            title="Тип материала"
            selected={materialType === "Операционный"}
            text="Операционный"
            onSelect={() => {
              dispatch(setMaterialType("Операционный"));
            }}
            tooltip={false}
            required={true}
          />
        </StyledRadioContainer>

        <StyledRadioContainer>
          <Radio
            selected={materialType === "Биопсийный"}
            text="Биопсийный"
            onSelect={() => {
              dispatch(setMaterialType("Биопсийный"));
            }}
            tooltip={false}
            required={true}
          />
        </StyledRadioContainer>
      </StyledRadioGroupContainer>

      <StyledSelectContainer>
        <Select
          title="Способ получения"
          required={true}
          placeholder="Выберите значение"
          options={[]}
          onSelect={(option) => {
            dispatch(setObtaining(""));
          }}
        />
      </StyledSelectContainer>

      <StyledRadioGroupContainer>
        <StyledRadioContainer>
          <Radio
            title="Тип образца"
            selected={sampleType === "Первичная опухоль"}
            text="Первичная опухоль"
            onSelect={() => {
              dispatch(setSampleType("Первичная опухоль"));
            }}
            tooltip={false}
            required={true}
          />
        </StyledRadioContainer>

        <StyledRadioContainer>
          <Radio
            selected={sampleType === "Метастаз"}
            text="Метастаз"
            onSelect={() => {
              dispatch(setSampleType("Метастаз"));
            }}
            tooltip={false}
            required={true}
          />
        </StyledRadioContainer>
      </StyledRadioGroupContainer>

      <StyledDatepickerContainer>
        <Datepicker
          required={true}
          title="Дата забора материала"
          placeholder=""
          value={date || ""}
          onChange={(event) => {
            dispatch(setDate(event.target.value));
          }}
        />
      </StyledDatepickerContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  padding-bottom: 200px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
`;

const StyledRadioContainer = styled.div`
  width: 100%;
`;

const StyledRadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledSelectContainer = styled.div`
  width: 100%;
`;

const StyledDatepickerContainer = styled.div``;
