import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { Select } from "../../../../../../componentsNew/Select";
import {
  diagnosesSelector,
  selectedDiagnosisSelector,
  selectedLocalizationSelector,
  setDiagnoses,
  setSelectedDiagnosis,
} from "../../../../redux/clinicalData";
import { useEffect } from "react";
import { useHttpClient } from "../../../../../../hooks/useHttpClient";
import { DiagnosesService } from "../../../../../../services/Diagnoses";

export const MkbDiagnosis = () => {
  const dispatch = useDispatch();

  const httpClient = useHttpClient();

  const diagnoses = useSelector(diagnosesSelector);
  const selectedDiagnosis = useSelector(selectedDiagnosisSelector);

  const selectedLocalization = useSelector(selectedLocalizationSelector);

  const effect = async () => {
    if (!selectedLocalization?.value) {
      return;
    }

    const diagnosesService = new DiagnosesService(httpClient);

    const _diagnoses = await diagnosesService.searchDiagnoses({
      localization_id: parseInt(selectedLocalization?.value, 10),
    });

    if (_diagnoses?.length) {
      dispatch(setDiagnoses(_diagnoses));
    }
  };

  useEffect(() => {
    effect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocalization]);

  const onSelect = (option: { label: string; value: string }) => {
    dispatch(setSelectedDiagnosis(option));
  };

  return (
    <StyledRowContainer>
      <StyledSelectContainer>
        <Select
          title="Диагноз по МКБ-10"
          placeholder="Диагноз по МКБ-10"
          options={diagnoses.map((diagnosis) => ({
            label: `${diagnosis.code} ${diagnosis.name}`,
            value: String(diagnosis.id),
            selected: String(diagnosis.id) === selectedDiagnosis?.value,
          }))}
          onSelect={onSelect}
        />
      </StyledSelectContainer>
    </StyledRowContainer>
  );
};

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledSelectContainer = styled.div`
  width: 100%;
`;
