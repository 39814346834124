import styled from "styled-components";
import { Title } from "../../../../../../componentsNew/Title";
import { Input } from "../../../../../../componentsNew/Input";
import { Datepicker } from "../../../../../../componentsNew/Datepicker";
import { Radio } from "../../../../../../componentsNew/Radio";
import { Select } from "../../../../../../componentsNew/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  newRfProfileSelector,
  setNewRfProfileAddress,
  setNewRfProfileBirthdate,
  setNewRfProfileCardHospital,
  setNewRfProfileCardNumber,
  setNewRfProfileCitizenship,
  setNewRfProfileFirstName,
  setNewRfProfileLastName,
  setNewRfProfileOms,
  setNewRfProfilePassport,
  setNewRfProfilePatronymic,
  setNewRfProfileSexCode,
  setNewRfProfileSnils,
} from "../../../../redux/patient";
import { Description } from "../../../../../../componentsNew/Description";

const sexes = [
  {
    code: "M",
    name: "Мужской",
  },
  {
    code: "F",
    name: "Женский",
  },
];

export const NewRfProfile = () => {
  const dispatch = useDispatch();

  const clinics: any[] = [];

  const newRfProfile = useSelector(newRfProfileSelector);

  console.log("_______ newRfProfile", newRfProfile);

  const onClinicSelect = (option: { label: string; value: string }) => {
    dispatch(setNewRfProfileCardHospital(option));
  };

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Совпадений не найдено. Создайте профиль</Title>
      </StyledTitleContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Гражданство"
            required={true}
            placeholder=""
            value={newRfProfile.citizenship || ""}
            onChange={(event) => {
              console.log("_____Change", event);
              dispatch(setNewRfProfileCitizenship(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Серия и номер паспорта"
            required={true}
            placeholder=""
            value={newRfProfile.passport || ""}
            onChange={(event) => {
              dispatch(setNewRfProfilePassport(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Адрес регистрации"
            required={true}
            placeholder=""
            value={newRfProfile.address || ""}
            onChange={(event) => {
              dispatch(setNewRfProfileAddress(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Номер полиса ОМС"
            required={true}
            placeholder=""
            value={newRfProfile.oms || ""}
            onChange={(event) => {
              dispatch(setNewRfProfileOms(event.target.value));
            }}
          />
        </StyledInputContainer2>

        <StyledInputContainer2>
          <Input
            title="Номер СНИЛС"
            required={true}
            placeholder=""
            value={newRfProfile.snils || ""}
            onChange={(event) => {
              dispatch(setNewRfProfileSnils(event.target.value));
            }}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Имя"
            required={true}
            placeholder=""
            value={newRfProfile.firstName || ""}
            onChange={(event) => {
              dispatch(setNewRfProfileFirstName(event.target.value));
            }}
          />
        </StyledInputContainer2>

        <StyledInputContainer2>
          <Input
            title="Фамилия"
            required={true}
            placeholder=""
            value={newRfProfile.lastName || ""}
            onChange={(event) => {
              dispatch(setNewRfProfileLastName(event.target.value));
            }}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Отчество"
            required={true}
            placeholder=""
            value={newRfProfile.patronymic || ""}
            onChange={(event) => {
              dispatch(setNewRfProfilePatronymic(event.target.value));
            }}
          />
        </StyledInputContainer2>

        <StyledInputContainer2>
          <Datepicker
            required={true}
            title="Дата рождения"
            placeholder=""
            value={newRfProfile.birthdate || ""}
            onChange={(event) => {
              dispatch(setNewRfProfileBirthdate(event.target.value));
            }}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledRowContainer2>
        {sexes.map((sex) => (
          <StyledInputContainer2 key={sex.code}>
            <Radio
              title={sex.code === "M" ? "Пол" : undefined}
              selected={sex.code === newRfProfile.sexCode}
              text={sex.name}
              //   onSelect={() => onCitizenshipSelect(citizenship.code)}
              onSelect={() => {
                dispatch(setNewRfProfileSexCode(sex.code as "M" | "F"));
              }}
              tooltip={false}
              required={
                // citizenship.code === Constants.CITIZENSHIP_RUSSIAN_FEDERATION
                //   ? true
                //   : false
                sex.code === "M" ? true : false
              }
            />
          </StyledInputContainer2>
        ))}
      </StyledRowContainer2>

      <StyledAmbulatoryCardContainer>
        <StyledRowContainer style={{ marginBottom: "16px" }}>
          <Title level="3">Амбулаторная карта</Title>
        </StyledRowContainer>

        <StyledRowContainer style={{ marginBottom: "8px" }}>
          <Description>
            Убедитесь, что выбранная амбулаторная карта зарегистрирована в
            учреждении направителя
          </Description>
        </StyledRowContainer>

        <StyledRowContainer2>
          <StyledInputContainer2>
            <Input
              title="Номер амбулаторной карты"
              required={true}
              placeholder=""
              value={newRfProfile?.card?.number || ""}
              onChange={(event) => {
                dispatch(setNewRfProfileCardNumber(event.target.value));
              }}
            />
          </StyledInputContainer2>

          <StyledInputContainer2>
            <Select
              placeholder="УЗ"
              required={true}
              options={clinics.map((clinic) => ({
                label: clinic.name,
                value: String(clinic.id),
                selected: clinic.id === newRfProfile?.card?.hospital?.id,
              }))}
              onSelect={onClinicSelect}
            />
          </StyledInputContainer2>
        </StyledRowContainer2>
      </StyledAmbulatoryCardContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  /* margin-bottom: 32px; */
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;

const StyledInputContainer2 = styled.div`
  width: 50%;
`;

const StyledRowContainer2 = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;

  gap: 16px;
`;

const StyledAmbulatoryCardContainer = styled.div``;
