// если иное УЗ - Вариант 1

import styled from "styled-components";
import { Title } from "../../../../componentsNew/Title";
import { Input } from "../../../../componentsNew/Input";

import { Button } from "../../../../componentsNew/Button";

import { Description } from "../../../../componentsNew/Description";
import { useDispatch, useSelector } from "react-redux";
import { editedPatientSelector } from "../../redux/patient";
import {
  searchLastNameSelector,
  setSearchLastName,
} from "../../redux/referrer";
import { useDoctors } from "../../hooks/useDoctors";
import { DoctorFound } from "./components/DoctorFound";

export const Referrer = () => {
  const dispatch = useDispatch();

  const editedPatient = useSelector(editedPatientSelector);

  const searchLastName = useSelector(searchLastNameSelector);

  const { searchDoctors } = useDoctors();

  const onSearch = async () => {
    if (searchLastName && editedPatient?.ambulatoryCard?.organizationId) {
      await searchDoctors({
        lastName: searchLastName,
        organization: editedPatient?.ambulatoryCard?.organizationId,
      });
    }
  };

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Данные направителя</Title>
      </StyledTitleContainer>

      <StyledRowContainer>
        <Description>
          УЗ направляющей стороны должно совпадать с учреждением, выдавшим
          амбулаторную карту пациенту
        </Description>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="УЗ"
            required={true}
            placeholder=""
            value={editedPatient?.ambulatoryCard?.organizationName || ""}
            onChange={(event) => {
              //   dispatch(setNewRfProfileAddress(event.target.value));
            }}
            disabled={true}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Фамилия"
            required={true}
            placeholder=""
            value={searchLastName || ""}
            onChange={(event) => {
              dispatch(setSearchLastName(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      {/* <StyledRowContainer>
        <Title level="3">Дата фактического направления на исследование</Title>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Datepicker
            required={true}
            title="Дата"
            placeholder=""
            value={""}
            onChange={(event) => {
              // dispatch(setNewRfProfileBirthdate(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer> */}

      <StyledPersonalDataButtonsContainer>
        <Button
          size="L"
          type="SECONDARY"
          text="Очистить"
          onClick={() => {
            // onClear();
          }}
        />

        <Button
          size="L"
          type="PRIMARY"
          text="Найти врача"
          onClick={() => {
            onSearch();
          }}
          disabled={!searchLastName}
        />
      </StyledPersonalDataButtonsContainer>

      {/* <DoctorNotFound /> */}

      <DoctorFound />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  padding-bottom: 200px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;

const StyledPersonalDataButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 32px;
`;
