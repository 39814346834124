import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";

export type ResearchTypeState = {
  code: string | null;
  name: string | null;
};

const DEFAULT_RESEARCH_TYPE = {
  code: "IGH",
  name: "Иммуногистохимическое исследование",
};

const initialState: ResearchTypeState = DEFAULT_RESEARCH_TYPE;

export const researchTypeSlice = createSlice({
  name: "researchType",
  initialState,
  reducers: {
    setResearchType: (
      state,
      action: PayloadAction<{ code: string; name: string }>
    ) => {
      state.code = action.payload.code;
      state.name = action.payload.name;
    },
  },
});

export const { setResearchType } = researchTypeSlice.actions;

export const researchTypeReducer = researchTypeSlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

export const researchTypeSelector = createSelector(
  getState,
  (state) => state.researchType
);
